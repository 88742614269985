import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { filter, orderBy, partition } from "lodash";
import { StatefulActionCheckBox } from "components/Forms/CustomInputs/StatefulActionCheckbox";
import { FormErrorText } from "theme/StyledComponents";
import { OverflowTooltip } from "components/OverflowTooltip";
import { ACTION_TYPES } from "../Actions/utils";
import { formatDateForDisplay } from "../../utils/date.utils";

export const availableActionsColumns = (handleSuccess) => [
  {
    field: "name",
    headerName: "Action",
    flex: 1.5,
    renderCell: (params) => <OverflowTooltip target={`actionName-${params.row.id}`} text={params.row.name} />
  },
  {
    field: "result",
    headerName: "Result",
    flex: 1.5,
    renderCell: (params) => <OverflowTooltip target={`actionResult-${params.row.id}`} text={params.row.result} />
  },
  {
    field: "lastDone",
    headerName: "Last Done",
    flex: 1,
    minWidth: 100,
    valueGetter: (params) =>
      params.row.lastDone ? DateTime.fromMillis(+params.row.lastDone).toFormat("MM-dd-yyyy") : "N/A"
  },
  {
    field: "timesDone",
    headerName: "Times/Applied",
    type: "number",
    flex: 1,
    headerAlign: "center",
    renderCell: (params) => {
      if (params.row.applyStrategy === "STATEFUL") {
        // eslint-disable-next-line
        const userId = useSelector((state) => state?.login?.userData?.sub);
        const shouldShowCheckBox = params?.row?.type === ACTION_TYPES.MANUAL_ENTRY_PARTICIPANT;

        return (
          <div className="MuiDataGrid-cellContent">
            {shouldShowCheckBox && (
              <StatefulActionCheckBox
                checked={params.row.statefulActionEnabled || params.row.lastInstancePending?.pending}
                customAction={{ id: params.row.id, name: params.row.name }}
                onSuccess={handleSuccess}
                userId={userId}
              />
            )}
            {!shouldShowCheckBox && <div className="text-center">{params.row.statefulActionEnabled ? "✓" : ""}</div>}

            {params.row?.lastInstancePending?.pending && (
              <FormErrorText className="mt-2">Awaiting approval</FormErrorText>
            )}
          </div>
        );
      } else {
        return <div className="MuiDataGrid-cellContent text-center">{params.row.timesDone}</div>;
      }
    }
  }
];

// TODO Users/helper.js:UserActionsColumns, UserDashboard/helper.js:actionLogColumns, and Insights/helper.js:allUsersActionsLogColumns should be combined.
export const actionLogColumns = [
  {
    field: "dateApplied",
    headerName: "Date Applied",
    flex: 0.75,
    minWidth: 100,
    valueGetter: (params) => {
      return formatDateForDisplay(params.row.dateApplied);
    }
  },
  {
    field: "creator",
    headerName: "Created By",
    cellClassName: "text-start",
    flex: 1,
    renderCell: (params) => {
      return <div style={{ wordWrap: "break-word" }}>{params.row.creator}</div>;
    }
  },
  {
    field: "name",
    headerName: "Action",
    flex: 1.75,
    renderCell: (params) => {
      return params.row.expired ? (
        <div>
          {params.row.name}
          <br />
          <span style={{ color: "red" }}>Expired {formatDateForDisplay(params.row.expirationDate)}</span>
        </div>
      ) : params.row.expirationDate ? (
        <div>
          <div>{params.row.name}</div>
          <div>{`Expires ${formatDateForDisplay(params.row.expirationDate)}`}</div>
        </div>
      ) : (
        <div>{params.row.name}</div>
      );
    }
  },
  {
    field: "actionDate",
    headerName: "Action Date",
    type: "date",
    flex: 1,
    cellClassName: "text-start",
    headerAlign: "left",
    renderCell: (params) => {
      return <div>{formatDateForDisplay(params.row.actionDate)}</div>;
    }
  },
  {
    field: "result",
    headerName: "Result",
    flex: 2,
    renderCell: (params) => {
      let value = `${params.row.attributeName} ${params.row.operator} ${params.row.displayValue}`;

      if (params.row.approvalStatus === "APPROVED") {
        return <div className="MuiDataGrid-cellContent">{value}</div>;
      }

      // TODO use an enum for approval status
      switch (params.row.approvalStatus) {
        case "REJECTED":
          value = "REJECTED";
          break;
        case "PENDING":
          value = "Awaiting Approval";
          break;
        case "LIMIT_EXCEEDED_TIMEFRAME":
          value = "Limit Exceeded";
          break;
        case "EXPIRED_NOT_APPROVED":
          value = "Expired: Not Approved";
          break;
      }
      return (
        <strong className="MuiDataGrid-cellContent" style={{ color: "red" }}>
          {value}
        </strong>
      );
    }
  },
  {
    field: "calculatedValue",
    headerName: "Value",
    flex: 0.75,
    minWidth: 100,
    valueGetter(params) {
      if (params.row.approvalStatus === "APPROVED") {
        return params.row.calculatedValue;
      }
    }
  }
];

export const getOrderedActions = (actions) => {
  const ordered = orderBy(actions, ({ dateApplied, actionDate }) => dateApplied || actionDate || "", ["desc"]);
  // don't show pending stateful actions in table
  const filtered = filter(
    ordered,
    (action) => !(action.approvalStatus === "PENDING" && action?.customAction?.applyStrategy === "STATEFUL")
  );
  const [needsApproval, rest] = partition(filtered, {
    approvalStatus: "PENDING"
  });

  return [...needsApproval, ...rest];
};
