import { orderBy } from "lodash";
import { formatDateForDisplay } from "../../utils/date.utils";

// TODO Users/helper.js:UserActionsColumns, UserDashboard/helper.js:actionLogColumns, and Insights/helper.js:allUsersActionsLogColumns should be combined.
export const allUsersActionsLogColumns = [
  {
    field: "dateApplied",
    headerName: "Date Applied",
    flex: 1.5,
    minWith: 150,
    valueGetter: (params) => formatDateForDisplay(params.row.dateApplied)
  },
  {
    field: "creator",
    headerName: "Created By",
    cellClassName: "text-start",
    flex: 1.5,
    renderCell: (params) => {
      return <div>{params.row.creator}</div>;
    }
  },
  {
    field: "user",
    headerName: "User",
    flex: 1,
    renderCell: (params) => {
      return <div style={{ wordWrap: "break-word" }}>{params.row?.user}</div>;
    }
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1.25,
    renderCell: (params) => {
      return <div style={{ wordWrap: "break-word" }}>{params.row?.userEmail}</div>;
    }
  },
  {
    field: "name",
    headerName: "Action",
    flex: 2,
    renderCell: (params) => {
      return params.row.expired ? (
        <div>
          {params.row.name}
          <br />
          <span style={{ color: "red" }}>Expired {formatDateForDisplay(params.row.expirationDate)}</span>
        </div>
      ) : params.row.expirationDate ? (
        <div>
          <div>{params.row.name}</div>
          <div>{`Expires ${formatDateForDisplay(params.row.expirationDate)}`}</div>
        </div>
      ) : (
        <div>{params.row.name}</div>
      );
    }
  },
  {
    field: "actionDate",
    headerName: "Action Date",
    type: "date",
    flex: 1,
    cellClassName: "text-start",
    headerAlign: "left",
    renderCell: (params) => {
      return <div>{formatDateForDisplay(params.row.actionDate)}</div>;
    }
  },
  {
    field: "result",
    headerName: "Result",
    flex: 1.5,
    renderCell: (params) => {
      // TODO query currently using result resolver. Switch it to using the action fields and delete result resolver. Formatting is a frontend responsibility.
      let value = params.row.result;

      if (params.row.approvalStatus === "APPROVED") {
        return <div className="MuiDataGrid-cellContent">{value}</div>;
      }

      // TODO use an enum for approval status
      switch (params.row.approvalStatus) {
        case "REJECTED":
          value = "REJECTED";
          break;
        case "PENDING":
          value = "Awaiting Approval";
          break;
        case "LIMIT_EXCEEDED_TIMEFRAME":
          value = "Limit Exceeded";
          break;
        case "EXPIRED_NOT_APPROVED":
          value = "Expired: Not Approved";
          break;
      }
      return (
        <strong className="MuiDataGrid-cellContent" style={{ color: "red" }}>
          {value}
        </strong>
      );
    }
  },
  {
    field: "calculatedValue",
    headerName: "Value",
    flex: 1.25,
    minWidth: 125,
    valueGetter(params) {
      if (params.row.approvalStatus === "APPROVED") {
        return params.row.calculatedValue;
      }
    }
  }
];

export const getOrderedActions = (actions) => {
  const ordered = orderBy(actions, ({ dateApplied, actionDate }) => dateApplied || actionDate || "", ["desc"]);
  return ordered;
};
