import React, { memo, useState, useCallback } from "react";
import styled from "styled-components";
import { Card, UncontrolledTooltip } from "reactstrap";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { find } from "lodash";

import { ActionFormButton } from "theme/StyledComponents";
import DefaultAvatar from "../../assets/user-icon.png";
import CustomSelect from "components/CustomSelect";
import { REMOVE_USER_FROM_PROJECT, UPDATE_USER_ACCOUNT_ROLE } from "api/users";
import { useMutation, useQuery } from "@apollo/client";
import { SEND_PROJECT_REMINDER_EMAIL } from "../../api/users";
import { useSubmit } from "hooks/form.hooks";
import { selectAccountData } from "features/userData/userDataSlice";
import messages from "../../api/messages";
import { useHasDirtyForm } from "../../hooks/form.hooks";
import DeleteRow from "components/DeleteRow/DeleteRow";
import { NEVER_LOGGED_IN } from "./helper";
import CustomSelectRadio from "components/CustomSelectRadio";
import { UPDATE_USER_TEAM } from "api/teams";
import { NO_TEAM } from "../../utils/team.utils";
import { FEATURE_FLAGS } from "../../hocs/FeatureFlag/utils";
import { FeatureFlag } from "hocs/FeatureFlag/FeatureFlag";
import RoleLastModified from "../../components/UserCard/RoleLastModified";
import ConfirmationModal from "../../components/ConfirmationModal";
import { GET_ROLES } from "../../api/accounts";
import { formatDateForDisplay } from "../../utils/date.utils";

const styles = {
  imageBorder: "#5da943",
  cardHover: "rgb(226, 237, 255, 0.4)",
  cardSelected: "rgb(226, 237, 255.6)",
  scrollBackground: "#e2f8e9"
};

export const StyledCard = styled(Card).attrs({
  className: "shadow-sm"
})`
  cursor: pointer;
  background: ${(props) => (props["data-selected"] ? styles.cardSelected : "white")};
  padding-left: 0.5rem;
  :hover {
    background: ${styles.cardHover};
  }
`;

function LastSeen({ lastLoggedIn }) {
  return !!lastLoggedIn ? (
    <p className="m-0">Last seen {formatDateForDisplay(lastLoggedIn)}</p>
  ) : (
    <p className="m-0" style={{ color: "red" }}>
      {NEVER_LOGGED_IN}
    </p>
  );
}

function UserCard({ isSelected, user, userId, idx, parentForm, queries, setDirtyForms, teamContext = {}, projectId }) {
  const { data: getRoles } = useQuery(GET_ROLES);
  const currentAccount = useSelector(selectAccountData);
  const { projectData } = useSelector((state) => state.userData);

  const [edit, setEdit] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const toggleEdit = useCallback(() => setEdit(!edit), [edit, setEdit]);
  const toggleConfirm = useCallback(() => setShowConfirm(!showConfirm), [setShowConfirm, showConfirm]);

  const [sendProjectReminderEmail, { loading: sendProjectReminderLoading }] = useMutation(SEND_PROJECT_REMINDER_EMAIL);

  const [updateUserTeam] = useMutation(UPDATE_USER_TEAM);
  const [updateUserAccountRole] = useMutation(UPDATE_USER_ACCOUNT_ROLE);
  const [removeUserFromProject] = useMutation(REMOVE_USER_FROM_PROJECT);

  const getUserTeam = () => {
    return find(user.teams, (team) => team.accountId === currentAccount?.id) ?? NO_TEAM;
  };
  const defaultTeamId = getUserTeam().id;
  const isEditableView = !!queries.refetchOrgUsers;
  // * External Participant and Observed Only roles do no get emails and neither does someone who has logged in.
  let neverLoggedInAndRoleAllowsEmails = !user.lastLoggedIn;
  if (user?.roles.includes("external_participant") || user?.roles.includes("observed_only")) {
    neverLoggedInAndRoleAllowsEmails = false;
  }

  const handleRemoveSuccess = useCallback(
    (i) => {
      parentForm.remove(i);
      setTimeout(async () => {
        if (queries.refetchOrgUsers) {
          await queries.refetchOrgUsers();
        }
        if (queries.refetchProjectUsers) {
          await queries.refetchProjectUsers();
        }
      }, 500);
    },
    [parentForm, queries]
  );

  const getRoleDisplay = useCallback(() => {
    switch (true) {
      case user.roles.includes("admin"):
        return "admin";
      case user.roles.includes("team_observer"):
        return "team_observer";
      case user.roles.includes("observed_only"):
        return "observed_only";
      case user.roles.includes("external_participant"):
        return "external_participant";
      default:
        return "user";
    }
  }, [user.roles]);

  const roleDisplay = getRoleDisplay();

  const { formState, register, watch, reset, control } = useForm({
    defaultValues: {
      role: roleDisplay,
      teamId: defaultTeamId
    }
  });

  const role = watch("role");
  const teamId = watch("teamId");

  const removeUser = useSubmit({
    mutation: removeUserFromProject,
    variables: { userId, projectId: projectData?.id },
    dataPath: REMOVE_USER_FROM_PROJECT.definitions[0].name.value,
    useVariables: true,
    onSuccess: () => handleRemoveSuccess(idx)
  });

  const handleSendEmail = useSubmit(
    {
      mutation: sendProjectReminderEmail,
      variables: { userId, projectId: projectData?.id },
      dataPath: SEND_PROJECT_REMINDER_EMAIL.definitions[0].name.value,
      useVariables: true
    },
    { skip: !projectData?.id }
  );

  const updateRole = useSubmit(
    {
      mutation: updateUserAccountRole,
      dataPath: UPDATE_USER_ACCOUNT_ROLE.definitions[0].name.value,
      variables: {
        data: {
          userId,
          role,
          accountId: currentAccount?.id
        }
      },
      useVariables: true,
      onSuccess: toggleEdit
    },
    { skip: !currentAccount?.id }
  );

  const updateTeam = useSubmit(
    {
      mutation: updateUserTeam,
      dataPath: UPDATE_USER_TEAM.definitions[0].name.value,
      variables: {
        teamId,
        accountId: currentAccount?.id,
        selectedUserId: userId
      },
      useVariables: true,
      onSuccess: toggleEdit
    },
    { skip: !currentAccount?.id }
  );

  const handleUndo = useCallback(() => {
    if (formState.isDirty) {
      toggleConfirm();
    } else {
      toggleEdit();
      reset();
    }
  }, [toggleEdit, reset, formState, toggleConfirm]);

  const teamChange = defaultTeamId !== teamId;
  const roleChange = role !== roleDisplay;

  const handleSubmit = useCallback(() => {
    if (teamChange) {
      updateTeam();
    }
    if (roleChange) {
      updateRole();
    }
    reset({ teamId, role });
    queries.refetchProjectUsers();
  }, [reset, role, roleChange, updateRole, updateTeam, teamChange, teamId, queries]);

  useHasDirtyForm(Object.keys(formState.dirtyFields).length > 0, userId, setDirtyForms);

  const handleDeclineChangedFieldsModal = () => {
    toggleConfirm();
  };

  const handleChangedFieldsModal = () => {
    toggleConfirm();
    toggleEdit();
    reset();
  };

  const roleForDisplay = getRoles?.getRolesForDisplay.find((r) => r.role === role);

  return (
    <StyledCard
      data-selected={isSelected}
      data-edit={edit}
      data-testid={`${UserCard.displayName}-${user.email}`}
      className={UserCard.displayName}
    >
      <div className="card-body py-2">
        <div className="row pt-2">
          {edit && (
            <div className="d-flex flex-row justify-content-around mb-2">
              <img
                style={{
                  borderRadius: "50%",
                  border: `2px solid ${styles.imageBorder}`,
                  objectFit: "cover"
                }}
                src={user.avatar || DefaultAvatar}
                alt="avatar"
                width={75}
                height={75}
              />
              <div className="col-md-7 col-sm-9 ms-2">
                <h5 className="card-title mb-1">
                  <span data-testid="firstName">{user?.firstName ?? ""}</span>{" "}
                  <span data-testid="lastName">{user?.lastName ?? ""} </span>
                </h5>
                <h6 className="card-text mb-0">{user.jobTitle}</h6>
                <div>
                  <p style={{ fontSize: "0.9rem" }} className="text-muted text-start">
                    {roleForDisplay?.displayValue ?? ""}
                  </p>
                </div>

                <p style={{ fontSize: "1rem" }} className="text-muted text-start m-0">
                  {user.email}
                </p>
                <LastSeen lastLoggedIn={user.lastLoggedIn} />
                <FeatureFlag featureName={FEATURE_FLAGS.TEAMS_INTERNAL}>
                  {!edit && (
                    <p style={{ fontSize: "0.9rem" }} className="text-muted text-start m-0">
                      Team: <em>{user.teams?.[0]?.name ?? NO_TEAM.name}</em>
                    </p>
                  )}
                </FeatureFlag>
              </div>
              <ActionFormButton
                type="button"
                id="undo"
                className="btn shadow border-0 btn-outline-primary"
                buttonType="undo"
                onClick={handleUndo}
              >
                <i className="fa fa-undo" />
              </ActionFormButton>
            </div>
          )}
          {!edit && (
            <>
              <div className="col-md-3 col-sm-12 d-flex justify-content-center p-0">
                <img
                  style={{
                    borderRadius: "50%",
                    border: `2px solid ${styles.imageBorder}`,
                    objectFit: "cover"
                  }}
                  src={user.avatar || DefaultAvatar}
                  alt="avatar"
                  width={75}
                  height={75}
                />
              </div>
              <div className="col-md-9 col-sm-12">
                <h5 className="card-title mb-1">
                  <span data-testid="firstName">{user?.firstName}</span>{" "}
                  <span data-testid="lastName">{user?.lastName} </span>
                </h5>
                <h6 className="card-text">{user.jobTitle}</h6>
                {user && roleForDisplay && (
                  <RoleDisplay
                    roleForDisplay={roleForDisplay}
                    adminNotOnProject={!user?.projects?.includes(projectId)}
                  />
                )}

                <FeatureFlag featureName={FEATURE_FLAGS.TEAMS_INTERNAL}>
                  {!edit && (
                    <p style={{ fontSize: "0.9rem" }} className="text-muted text-start m-0">
                      Team: <em>{user.teams?.[0]?.name ?? NO_TEAM.name}</em>
                    </p>
                  )}
                </FeatureFlag>
                <p style={{ fontSize: "1rem" }} className="text-muted text-start m-0">
                  {user.email}
                </p>

                <LastSeen lastLoggedIn={user.lastLoggedIn} />
                <div></div>
              </div>
            </>
          )}
        </div>
        {edit && (
          <>
            <div className="d-flex align-items-center justify-content-between mt-2">
              <span>
                <CustomSelect
                  label="ROLE"
                  displayKey="displayValue"
                  formRegister={register}
                  name="role"
                  width="12rem"
                  height="2.5rem"
                  tooltips={true}
                  options={getRoles?.getRolesForDisplay}
                  defaultValue={roleForDisplay}
                  valueKey="role"
                  control={control}
                />
              </span>
              <RoleLastModified roleLastModified={user.roleUpdatedAt} />
            </div>
            <FeatureFlag featureName={FEATURE_FLAGS.TEAMS_INTERNAL}>
              <div className="d-flex align-items-center justify-content-start mt-3">
                <CustomSelectRadio
                  id={`USER-TEAM-SELECT-${idx}`}
                  testId="USER-TEAM-SELECT"
                  options={teamContext.teams}
                  name="teamId"
                  displayKey="display"
                  label="TEAM"
                  handleSuccess={() => {
                    teamContext.refetchTeams();
                    queries.refetchProjectUsers();
                  }}
                  defaultValue={teamId}
                  isAdmin={true}
                  formRegister={register}
                />
              </div>
            </FeatureFlag>
          </>
        )}

        {edit && (
          <div className="w-60 mx-auto justify-content-center d-flex mt-3">
            <UncontrolledTooltip hideArrow placement="top" target="undo">
              Close edit
            </UncontrolledTooltip>
            <UncontrolledTooltip hideArrow placement="top" target="delete">
              Remove user from project
            </UncontrolledTooltip>
            <UncontrolledTooltip hideArrow placement="top" target="submit">
              Update user role or team
            </UncontrolledTooltip>
            <ActionFormButton
              type="button"
              id="submit"
              onClick={handleSubmit}
              disabled={!formState.isDirty}
              className="btn btn-outline-success shadow border-0"
              data-testid="SUBMIT_USER_UPDATE"
            >
              <i className="fa fa-check" />
            </ActionFormButton>
            {neverLoggedInAndRoleAllowsEmails && (
              <>
                <UncontrolledTooltip hideArrow placement="top" target="sendEmail">
                  Send this user a reminder email
                </UncontrolledTooltip>
                <ActionFormButton
                  type="button"
                  id="sendEmail"
                  className="btn shadow border-0 btn-outline-dark ms-3 me-3"
                  buttonType="email"
                  disabled={sendProjectReminderLoading}
                  onClick={handleSendEmail}
                  data-testid="USER_CARD_SEND_REMINDER_EMAIL_BUTTON"
                >
                  <i className="fa-thin fa fa-paper-plane"></i>
                </ActionFormButton>
              </>
            )}
            {isEditableView && edit && (
              // * Make margin correct for three vs two buttons
              <div className={!neverLoggedInAndRoleAllowsEmails ? "ms-3" : ""}>
                <DeleteRow
                  confirmText={messages.CONFIRM_REMOVE_USER_FROM_PROJECT}
                  handleDelete={removeUser}
                  shouldUseModal
                  roundButton={true}
                />
              </div>
            )}
          </div>
        )}
        {!edit && isEditableView && (
          <div className="row d-inline float-end">
            <span onClick={toggleEdit} data-testid={`PARTICIPANT_EDIT-${user?.firstName}-${user?.lastName}`}>
              <i className="fa fa-edit" style={{ color: "green", transform: "scale(1.3)" }} />
            </span>
          </div>
        )}
      </div>
      {showConfirm && (
        <ConfirmationModal
          type="warning"
          toggle={handleDeclineChangedFieldsModal}
          handleSubmit={handleChangedFieldsModal}
          bodyText={messages.USER_CARD_UNSAVED_CHANGES}
        />
      )}
    </StyledCard>
  );
}
UserCard.displayName = "UserCard";
export default memo(UserCard);

// * RoleDisplay only used here on the UserCard component - tmp (AF 12/17/24): renders "Not In Project" for userAccount admins with no attributes
export const RoleDisplay = ({ roleForDisplay, user, adminNotOnProject = false }) => {
  const id = user?.id + roleForDisplay?.id.toString();
  return (
    <div>
      <p style={{ fontSize: "0.9rem" }} className="text-muted text-start m-0" id={id}>
        {roleForDisplay?.displayValue ?? ""}
        {roleForDisplay?.displayValue === "Admin" && adminNotOnProject ? " (Not In Project)" : ""}
      </p>
      <UncontrolledTooltip hideArrow placement="bottom" target={id}>
        {roleForDisplay?.tooltipText ?? ""}
      </UncontrolledTooltip>
    </div>
  );
};
