import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { debounce, find, isEmpty } from "lodash";
import { useMutation } from "@apollo/client";
import confirmAction from "reactstrap-confirm";

import CustomSelect from "components/CustomSelect";
import CustomDatePicker from "components/CustomDatePicker";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import { LOG_MANUAL_ENTRY_ACTION, SUBMIT_ACTION } from "api/actions";
import { selectSpecificOperators } from "app/operators.slice";
import { useCheckActionLimit } from "hooks/application.hooks";
import { setGlobalLoading } from "features/Common/CommonSlice";
import { ActionFormButton } from "theme/StyledComponents";
import { Prompt } from "react-router-dom";
import messages from "api/messages";
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import { getLimitDisplay } from "features/Actions/utils";
import { useGetUserAndActions } from "hooks/application";
import ActionExpirationForm from "./ActionExpirationForm";
import { DateTime } from "luxon";
import { FeatureFlag } from "hocs/FeatureFlag/FeatureFlag";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import { calculateExpirationDate, checkDateExpired } from "utils/date.utils";

function ActionForm({ handleClearForm, attributes, user, customActions, projectId, handleApproveOrRejectSuccess }) {
  const dispatch = useDispatch();
  const actionOptions = useMemo(
    () => [{ id: "MANUAL_ENTRY", name: "Manual Entry" }, ...customActions],
    [customActions]
  );
  const [selectedAction, setSelectedAction] = useState(null);
  const [showManualEntryForm, setShowManualEntryForm] = useState(true);
  const [plusOperator, assignmentOperator, minusOperator] = useSelector(selectSpecificOperators(["+", "=", "-"]));
  const wholeNumberOperators = [plusOperator, minusOperator, assignmentOperator];

  // TODO:: update what isValid is if expires is selected (bulk log branch does isValid in a useEffect which would be helpful here
  const [isValid, setIsValid] = useState(false);

  const checkActionLimit = useCheckActionLimit();

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { dirtyFields }
  } = useForm({
    defaultValues: {
      action: actionOptions[0].id,
      actionId: actionOptions[0].id,
      expirationPeriod: null,
      expirationPeriodAmount: null,
      operatorId: assignmentOperator,
      expires: false
    }
  });

  const watchedAction = watch("action");
  const name = watch("name");
  const value = watch("value");
  const expirationPeriod = watch("expirationPeriod");
  const expirationPeriodAmount = watch("expirationPeriodAmount");
  const operatorId = watch("operatorId");
  const attributeId = watch("attributeId", attributes[0]?.id);
  const expires = watch("expires", false);

  const [selectedAttribute, setSelectedAttribute] = useState(attributes[0]);
  const [submitActionMutation, { loading: actionLoading }] = useMutation(SUBMIT_ACTION);
  const [submitLogManualAction, { loading: manualActionLoading }] = useMutation(LOG_MANUAL_ENTRY_ACTION);
  const { loading: userLoading } = useGetUserAndActions();

  const getFormValuesFromAction = (action) => {
    return {
      value: action.value,
      operatorId: action.operator.id,
      attributeId: action.attribute.id,
      name: action.name,
      type: action.type,
      customActionId: action.id,
      expirationPeriod: action.expirationPeriod,
      expirationPeriodAmount: action.expirationPeriodAmount
    };
  };

  const onSubmit = useCallback(
    async (formData) => {
      // if no selectedAction (aka customAction) use the formData otherwise get the values from the selectedAction
      const base = isEmpty(selectedAction) ? formData : getFormValuesFromAction(selectedAction);

      // Will this action immediately expire?
      const calculatedExpirationDate = calculateExpirationDate(
        base.expirationPeriod,
        base.expirationPeriodAmount,
        formData.actionDate ? DateTime.fromSQL(formData.actionDate) : DateTime.now()
      );
      const alreadyExpired = checkDateExpired(calculatedExpirationDate);
      if (alreadyExpired) {
        const userConfirmedAutoExpiration = await confirmAction({
          confirmText: "Yes",
          cancelText: "No",
          message: messages.EXPIRED_ACTION_WARNING,
          confirmColor: "btn btn-success shadow border-0",
          cancelColor: "btn btn-danger shadow border-0"
        });
        if (!userConfirmedAutoExpiration) {
          return;
        }
      }

      // Is this action within the time limit?
      const { success: withinTimeFrame } = await checkActionLimit(base?.customActionId ?? "", user?.id);
      if (!withinTimeFrame) {
        const userConfirmedSubmitOutsideTimeframe = await confirmAction({
          confirmText: "Yes",
          cancelText: "No",
          message: `This action is limited to ${getLimitDisplay(
            selectedAction?.limit
          )}. Adding this action will ignore the limit. Are you sure you want to proceed?`,
          confirmColor: "btn btn-success shadow border-0",
          cancelColor: "btn btn-danger shadow border-0"
        });
        if (!userConfirmedSubmitOutsideTimeframe) {
          return;
        }
      }

      // Is it a defined project action or a manual action?
      if (base.customActionId) {
        await submitActionMutation({
          variables: {
            data: {
              actionDate: formData.actionDate,
              customActionId: base.customActionId,
              userId: user.id
            }
          }
        });
      } else {
        await submitLogManualAction({
          variables: {
            data: {
              name: base.name,
              value: base.value,
              attributeId: selectedAttribute.id,
              operatorId: base.operatorId,
              projectId: projectId,
              userId: user.id,
              actionDate: formData.actionDate,
              expirationDate: calculatedExpirationDate
            }
          }
        });
      }

      handleApproveOrRejectSuccess();
      handleClearForm();
    },
    [
      handleClearForm,
      checkActionLimit,
      selectedAttribute?.id,
      submitLogManualAction,
      selectedAction,
      user?.id,
      projectId,
      handleApproveOrRejectSuccess,
      submitActionMutation
    ]
  );

  const debouncedOnSubmit = debounce(onSubmit, 1000, { leading: true });

  useEffect(() => {
    if (userLoading || actionLoading || manualActionLoading) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
    return () => dispatch(setGlobalLoading(false));
  }, [dispatch, userLoading, actionLoading, manualActionLoading]);

  // TODO(MB) refactor into custom hook

  useEffect(() => {
    if (!!attributeId) {
      const selectedAttr = find(attributes, (opt) => opt.id === attributeId);
      setSelectedAttribute(selectedAttr);
    }
  }, [attributeId, attributes, setValue]);

  // TODO(MB) refactor into custom hook
  useEffect(() => {
    if (watchedAction !== "MANUAL_ENTRY") {
      const action = actionOptions.find((action) => action.id === watchedAction);
      if (action) {
        setSelectedAction(action);
      }
    }
  }, [watchedAction, actionOptions, attributes]);

  // TODO(MB) refactor into custom hook
  useEffect(() => {
    if (!!plusOperator && !!assignmentOperator && selectedAttribute) {
      const operatorId =
        selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? plusOperator.id : assignmentOperator.id;
      reset({ attributeId: selectedAttribute?.id, operatorId });
    }
  }, [selectedAttribute?.type, assignmentOperator, plusOperator, selectedAttribute, reset]);

  useEffect(() => {
    if (!expires) {
      setIsValid(!watchedAction || watchedAction === "MANUAL_ENTRY" ? !!name?.trim() && !!value : true);
    } else {
      setIsValid(
        expires &&
          expirationPeriod &&
          expirationPeriodAmount &&
          (!watchedAction || watchedAction === "MANUAL_ENTRY" ? !!name?.trim() && !!value : true)
      );
    }
  }, [watchedAction, name, value, expirationPeriodAmount, expirationPeriod, expires, setIsValid]);

  const handleExpirationValues = (values) => {
    if (values.expires) {
      setValue("expirationPeriod", values.period);
      setValue("expirationPeriodAmount", +values.periodAmount);
      setValue("expires", values.expires);
    } else if (!isValid) {
      setValue("expires", false);
    }
  };

  // * Simplified hook to determine form state
  useEffect(() => {
    if (watchedAction !== "MANUAL_ENTRY") {
      setShowManualEntryForm(false);
    } else {
      setShowManualEntryForm(true);
    }
  }, [watchedAction, setShowManualEntryForm]);

  return (
    <form
      className="d-inline-flex justify-content-start grid gap-2 w-100 align-items-center flex-wrap"
      onSubmit={handleSubmit(debouncedOnSubmit)}
      data-testid="ACTION_FORM"
    >
      <Prompt
        when={selectedAttribute !== attributes[0] || Object.keys(dirtyFields).length > 0}
        message={messages.UNSAVED_CHANGES}
      />
      <CustomDatePicker formRegister={register} name="actionDate" width="9rem" />
      <CustomSelect
        label="ACTION"
        formRegister={register}
        name="action"
        options={actionOptions}
        width="10rem"
        defaultValue={actionOptions.find((a) => a.id === watchedAction) ?? actionOptions[0].id}
        displayKey="name"
        valueKey="id"
        control={control}
      />
      {showManualEntryForm ? (
        <>
          <UncontrolledInput labelText="NAME" formRegister={register} name="name" maxWidth="8rem" />
          <strong className="mx-1">=</strong>
          <CustomSelect
            label="ATTRIBUTE"
            formRegister={register}
            name="attributeId"
            options={attributes}
            defaultValue={attributes.find((att) => att.id === attributeId) ?? attributes[0]}
            width="9rem"
            displayKey="name"
            control={control}
          />
          {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? (
            <CustomSelect
              label="OPERATOR"
              formRegister={register}
              name="operatorId"
              width="4rem"
              defaultValue={wholeNumberOperators.find((op) => op.id === operatorId) ?? wholeNumberOperators[0]}
              options={wholeNumberOperators}
              control={control}
            />
          ) : (
            <CustomSelect
              label="OPERATOR"
              formRegister={register}
              name="operatorId"
              width="4rem"
              defaultValue={[assignmentOperator][0]}
              options={[assignmentOperator]}
              control={control}
            />
          )}
          {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? (
            <UncontrolledInput labelText="VALUE" formRegister={register} name="value" maxWidth="4rem" type="number" />
          ) : (
            <CustomSelect
              label="VALUE"
              formRegister={register}
              name="value"
              displayKey="name"
              control={control}
              defaultValue={
                selectedAttribute?.attributeValues.find((att) => att.id === value) ??
                selectedAttribute?.attributeValues[0]
              }
              options={selectedAttribute?.attributeValues}
            />
          )}
        </>
      ) : (
        <>
          <strong className="mx-1">=</strong>
          <CustomActionTextDisplay action={selectedAction} />
        </>
      )}
      <span className="d-flex grid gap-2" style={{ marginLeft: "auto" }}>
        <ActionFormButton
          data-testid="SUBMIT_ACTION"
          disabled={actionLoading || userLoading || manualActionLoading || !isValid}
          type="submit"
          className="btn btn-outline-success shadow border-0"
        >
          <i className="fa fa-check" />
        </ActionFormButton>
        <ActionFormButton
          type="button"
          className="btn border-1 text-danger btn-lg justify-content-center d-flex"
          onClick={handleClearForm}
          buttonType="delete"
        >
          <i className="fa fa-times" />
        </ActionFormButton>
      </span>
      <FeatureFlag featureName={FEATURE_FLAGS.EXPIRING_ACTIONS}>
        {isEmpty(selectedAction) && <ActionExpirationForm emitExpiration={handleExpirationValues} />}
      </FeatureFlag>
    </form>
  );
}

function CustomActionTextDisplay({ action }) {
  if (isEmpty(action)) {
    return null;
  }
  return (
    <div className="d-flex justify-content-around" style={{ maxWidth: "35%", width: "100%" }}>
      <span>{action.result}</span>
    </div>
  );
}

export default ActionForm;
